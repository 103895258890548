import activity from './m-agenda.vue';

export default activity;

export const componentRegistrationData = {
  name: 'Agenda',
  icon: 'mdi-calendar-clock',
  description: 'Display event schedule for attendees',
  status: true,
  setupRequired: true,
  styling: {
    borderColor: '#3c9ccc'
  },
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-agenda',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {
        eventName: '',
        eventDate: '',
        timeFrame: [
          {
            id: 0,
            start: '',
            end: '',
            name: '',
            notes: '',
            isCondensed: true,
            visibility: 'visible'
          }
        ],
        timeZone: ''
      },
      value: {}
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
