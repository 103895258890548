
import { defineComponent, computed, ref } from '@vue/composition-api';
import axios from 'axios';
// import { VDatePicker } from 'vuetify/labs/VDatePicker';
import { Field } from '../types';

interface OrganizerConfig {
  eventName: string;
  eventDate: string;
  timeFrame: {
    id: number;
    start: string;
    end: string;
    name: string;
    notes: string; // Stores the description
    isCondensed: boolean;
    visibility: string;
  }[];
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ParticipantConfig {}

interface ProjectField extends Field {
  value: ParticipantConfig;
  setup: OrganizerConfig;
}

export default defineComponent({
  // TODO: rename to what matches your project in the same format. Ex: MProjectPresentation
  name: 'MAgenda',
  components: {
    // VDatePicker
  },
  props: {
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(_props, _ctx) {
    const apiErrors = ref<Array<string>>([]);
    const userIsHost = ref(_props.userType === 'organizer');
    const programDocument: any = computed({
      get: () => _props.value,
      set: newVal => {
        _ctx.emit('input', newVal);
      }
    });

    const adkIndex = computed(() => {
      const indx = programDocument.value?.data.adks?.findIndex(adk => {
        if (adk) {
          return adk.name === 'Agenda';
        }
        return false;
      });
      return indx;
    });

    const fieldIndex = computed(() => {
      const fieldIndex = programDocument.value?.data?.adks?.[adkIndex.value]?.fields?.findIndex(
        field => {
          if (field.name) {
            return field.name === 'm-agenda';
          }
          return false;
        }
      );
      return fieldIndex;
    });

    const fieldValue = computed<ProjectField>({
      get: () => programDocument.value?.data?.adks?.[adkIndex.value]?.fields[fieldIndex.value],
      set: newVal => {
        if (fieldValue.value)
          programDocument.value.data.adks[adkIndex.value].fields[fieldIndex.value] = newVal;
      }
    });

    const organizerConfig = computed<OrganizerConfig>({
      get: () => fieldValue.value.setup,
      set: newVal => {
        fieldValue.value.setup = newVal;
      }
    });
    const participantConfig = computed<ParticipantConfig>({
      get: () => fieldValue.value.value,
      set: newVal => {
        fieldValue.value.value = newVal;
      }
    });

    // async function discordLog(message) {
    //   const response = await fetch(
    //     'WEBHOOK URL HERE',
    //     {
    //       method: 'POST',
    //       body: JSON.stringify({
    //         content: `Log: ${message}`,
    //         username: 'PilotCity Agenda'
    //       }),
    //       headers: {
    //         'Content-Type': 'application/json'
    //       }
    //     }
    //   );
    // }

    const testFrames = ref([
      {
        id: 0,
        start: '9:00 AM',
        end: '10:15 AM',
        name: 'Morning Meeting',
        notes: 'Goals to be set for today, discussion of project status',
        isCondensed: true,
        visibility: 'visible'
      },
      {
        id: 1,
        start: '10:30 AM',
        end: '11:00 AM',
        name: 'Coffee Break',
        notes: 'Decaf only',
        isCondensed: true,
        visibility: 'visible'
      }
    ]);

    console.log('Console was logged');
    console.log(organizerConfig.value.timeFrame);
    console.log(typeof organizerConfig.value.timeFrame);
    let i = 5;

    const hostActions = {
      addTimeFrame() {
        console.log(organizerConfig.value);
        organizerConfig.value.timeFrame.push({
          id: i++,
          start: '',
          end: '',
          name: '',
          notes: '',
          isCondensed: true,
          visibility: 'visible'
        });
        // discordLog('Added new time frame');
      },
      removeTimeFrame(item) {
        organizerConfig.value.timeFrame = organizerConfig.value.timeFrame.filter(t => t !== item);
        // discordLog('Removed time frame');
      }
    };

    const wordedDate = computed(() => {
      const date = new Date(organizerConfig.value.eventDate);
      return date.toLocaleString('en-us', { dateStyle: 'full' });
    });

    const expandedPanels = computed(() => {
      const x: Array<number> = [];
      for (let i = 0; i < organizerConfig.value.timeFrame.length; i++) {
        // Add (&& i < num) to condition in for loop to ensure a set amount of open panels by default
        x.push(i);
      }
      return x;
    });

    return {
      fieldValue,
      participantConfig,
      organizerConfig,
      userIsHost,
      apiErrors,
      hostActions,
      testFrames,
      wordedDate,
      expandedPanels
      // discordLog
    };
  }
});
